import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FourOhFour from '../components/FourOhFour';
import { ShowAdminUsers } from '../admin-only-routes/show-admin-users';
import { User } from '../redux/prop-types';
import { userSelector } from '../redux/selectors';

interface AdminUsersProps {
  user: User;
}

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

function AdminUsers({ user }: AdminUsersProps): JSX.Element {
  return (
    <Router>
      {user.type === 'admin' ? (
        <ShowAdminUsers path={withPrefix('/admin-users')} />
      ) : null}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <FourOhFour default={true} />
    </Router>
  );
}

AdminUsers.displayName = 'Admin Users';

export default connect(mapStateToProps, null)(withTranslation()(AdminUsers));
