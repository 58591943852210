import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  createFlashMessage,
  removeFlashMessage
} from '../components/Flash/redux';
import { hardGoTo as navigate } from '../redux/actions';
import { User } from '../redux/prop-types';
import { userSelector, userTokenSelector } from '../redux/selectors';

const mapStateToProps = createSelector(
  userSelector,
  userTokenSelector,
  (user: User, userToken: string | null) => ({
    user,
    userToken
  })
);

const mapDispatchToProps = {
  createFlashMessage,
  removeFlashMessage,
  navigate
};

type ShowAdminUsersProps = {
  path: string;
};

export function ShowAdminUsers(props: ShowAdminUsersProps): JSX.Element {
  console.log(props);
  return <></>;
}

ShowAdminUsers.displayName = 'ShowAdminUsers';

export default connect(mapStateToProps, mapDispatchToProps)(ShowAdminUsers);
